import { Table, TableTitle, TableRow, TableContent } from 'components/table/style';

const Venue = () => (
  <Table>
    <TableTitle>Venue details</TableTitle>

    <TableRow>
      <TableContent>Graceful Aura Training Academy</TableContent>
    </TableRow>
    <TableRow>
      <TableContent>7 Woolton Street</TableContent>
    </TableRow>
    <TableRow>
      <TableContent>Woolton Village</TableContent>
    </TableRow>
    <TableRow>
      <TableContent>L25 5NH</TableContent>
    </TableRow>
  </Table>
);

export default Venue;
